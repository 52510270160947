import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import {
  message,
  Input,
  Button,
  Form,
  Space,
  Tooltip,
  Table,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  deleteConfig,
  getAllConfig,
  insertConfig,
  updateConfig,
} from "../../helpers/helper";
import { Drawer } from "antd";
import Editor from "../../Components/Common/Editor";

const Configs = () => {
  document.title = "Management Configs";

  const [form] = Form.useForm();
  const [listConfig, setListConfig] = useState([]);
  const [isShow, setIsShow] = useState(true);
  const [visibleForm, setVisibleForm] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const editorContentRef = useRef(null);
  const [contentData, setContentData] = useState("");

  useEffect(() => {
    async function fetchData() {
      const dataRes = await getAllData();
      setListConfig(dataRes);
    }
    fetchData();
  }, []);
  const getAllData = async (_prams) => {
    const dataRes = await getAllConfig();
    const data =
      dataRes?.data &&
      dataRes?.data.length > 0 &&
      dataRes?.data.map((item) => {
        return {
          key: item._id,
          name: item.name,
          value: item.value,
          content: item.content
        };
      });
    return dataRes?.data ? data : [];
  };

  const onFinish = async (data) => {
    const dataReq = {
      name: data.name,
      value: data.value,
      content: editorContentRef.current
    };
    if (!data._id) {
      //Save
      const dataRes = await insertConfig(dataReq);
      if (dataRes.status === 1) {
        message.success(`Lưu thành công! ${dataRes.message}`);
        setVisibleForm(false);
        handleCloseDrawer();
      } else {
        message.error(`Save Failed! ${dataRes.message}`);
      }
    } else {
      //Update

      const dataRes = await updateConfig(data._id, dataReq);
      if (dataRes.status === 1) {
        message.success(`Lưu thành công! ${dataRes.message}`);
        handleCloseDrawer();
      } else {
        message.error(`Save Failed! ${dataRes.message}`);
      }
    }
    const dataRes = await getAllData();
    setListConfig(dataRes);
    form.resetFields();
  };

  const handleRefresh = async () => {
    form.resetFields();
    const dataRes = await getAllData();
    setListConfig(dataRes);
    setContentData("");
    editorContentRef.current = "";
  };


  const onEdit = async (key) => {
    const dataEdit = listConfig.filter((item) => item.key === key);
    setIsShow(dataEdit[0].isShow);
    form.setFieldsValue({
      _id:dataEdit[0].key,
      name: dataEdit[0].name,
      value: dataEdit[0].value,
    });
    setDrawerTitle("Sửa Config");
    setContentData(dataEdit[0].content);
    editorContentRef.current = dataEdit[0].content;
    showDrawer();
  };

  const onDelete = async (key) => {
    if(window.confirm("Bạn có chắc muốn xóa không?")){
      const dataRes = await deleteConfig(key);
      dataRes.status === 1
        ? message.success(`Xóa thành công! ${dataRes.message}`)
        : message.error(`Xóa thất bại! ${dataRes.message}`);
  
      handleRefresh();
    }
  };


  const handleNewConfig = () => {
    setContentData("");
    editorContentRef.current = "";
    setDrawerTitle("Thêm Config");
    showDrawer();
    form.resetFields();
  };
  const onClose = () => {
    setVisibleForm(false);
  };

  const columns = [
    {
      title: "Tên config",
      dataIndex: "name",
    },
    {
      title: "value",
      dataIndex: "value",
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) =>
        listConfig.length >= 1 ? (
          <Space>
            <Tooltip title="Sửa">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => onEdit(record.key)}
              />
            </Tooltip>
            <Tooltip title="Xóa">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onDelete(record.key)}
              />
            </Tooltip>
          </Space>
        ) : null,
    },
  ];

  const showDrawer = () => {
    setVisibleForm(true);
  };

  const handleCloseDrawer = () => {
    setVisibleForm(false);
    form.resetFields();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Danh mục" pageTitle="Quản lí danh mục" />

          <div>
            <Drawer
              title={drawerTitle}
              placement={"right"}
              width={"70%"}
              onClose={onClose}
              open={visibleForm}
              bodyStyle={{
                paddingBottom: 80,
              }}
              style={{ marginTop: "70px" }}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row>
                  <Col hidden={true}>
                    <Form.Item name="_id" label="Id">
                      <Input name="_id"/>
                    </Form.Item>
                  </Col>
                  <Form.Item
                    name="name"
                    label="Tên Config"
                    rules={[
                      {
                        required: true,
                        message: "Please input config  name!",
                      },
                      {
                        type: "name",
                      },
                      {
                        type: "string",
                        min: 1,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter name"
                      name="name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="value"
                    label="Value"
                  >
                    <Input
                      placeholder="Enter value"
                      name="value"
                      allowClear={true}
                    />
                  </Form.Item>
                  <Col xs={12}>
                    <div className="ant-col ant-form-item-label">
                      <label
                        htmlFor="content"
                        className="ant-form"
                        title="content"
                      >
                        Content
                      </label>
                    </div>
                    <Editor
                      value={contentData}
                      onChange={(v) => editorContentRef.current = v}
                    />
                  </Col>  
                </Row>
                <Form.Item className="mt-3">
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Lưu
                    </Button>
                    <Button
                      type="info"
                      htmlType="button"
                      onClick={() => handleRefresh()}
                    >
                      Làm mới trang
                    </Button>
                    <Button type="danger" onClick={handleCloseDrawer}>
                      Đóng
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Drawer>
          </div>
          <Row>
            <Col xs={12}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Col hidden={true}>
                  <Form.Item name="id" label="Id">
                    <Input name="id" />
                  </Form.Item>
                </Col>
                <Form.Item className="mt-3">
                  <Space>
                    <Button type="primary" onClick={handleNewConfig}>
                      Tạo mới
                    </Button>

                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRefresh()}
                    >
                       Làm mới trang
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <div>
            <Table columns={columns} dataSource={listConfig} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Configs;