import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import {
  message,
  Input,
  Button,
  Form,
  Space,
  Select,
  Tooltip,
  Table,
  Drawer,
  Upload,
  Modal,
  Image,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {
  deleteUser,
  getAllRole,
  getPagingUser,
  insertUser,
  updateUser,
} from "../../../helpers/helper";
import moment from "moment";
import BreadCrumb from "../../../common/BreadCrumb";
import UserStatus from "../../../store/status/userStatus";
import Editor, { UploadService } from "../../../Components/Common/Editor";

const { Option } = Select;

const Users = () => {
  document.title = "Management Users";

  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [listUser, setListUser] = useState([]);
  const [listRole, setListRole] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [visibleForm, setVisibleForm] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const refDescription = useRef("");
  const refContent = useRef("");
  const [previewImage, setPreviewImage] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [contentData, setContentData] = useState("");
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [listUserSearch, setListUserSearch] = useState([]);

  const refId = useRef("common");
  useEffect(() => {
    async function fetchData() {
      const dataRes = await getAllData();
      setListUser(dataRes);
      setListUserSearch(dataRes)
      //get role
      const resListRole = await getAllRole();
      setListRole(resListRole);
      setListStatus(UserStatus);
    }
    fetchData();
  }, []);

  const getAllData = async (_prams) => {
    const params = _prams
      ? _prams
      : {
          pageIndex: 1,
          pageSize: 100000,
          search: "",
        };
    const dataRes = await getPagingUser(params);

    const data =
      dataRes &&
      dataRes.length > 0 &&
      dataRes.map((item) => {
        return {
          key: item._id,
          ...item,
          userName: item.userName,
          fullName: item.fullName,
          activeStatus:
          item.activeStatus === 1 ? "Kích hoạt" : "Ngưng kích hoạt",
          createdTime: moment(new Date(item.createdTime)).format("DD/MM/YYYY"),
        };
      });
    return dataRes ? data : [];
  };

  const onFinish = async (data) => {
    const dataReq = {
      userName: data.userName,
      fullName: data.fullName,
      activeStatus: data.activeStatus,
      role: data.role,
    };
    if(data.password){
      dataReq.password = data.password;
    }
    if (!data.id) {
      //Save
      const dataRes = await insertUser(dataReq);
      dataRes.status === 1
        ? message.success(`Lưu thành công! ${dataRes.message}`)
        : message.error(`Save Failed! ${dataRes.message}`);
      if (dataRes.status === 1) {
        onClose();
      }
    } else {
      //Update
      const dataRes = await updateUser(data.id, dataReq);
      dataRes.status === 1
        ? message.success(`Update Success! ${dataRes.message}`)
        : message.error(`Update Failed! ${dataRes.message}`);
      if (dataRes.status === 1) {
        onClose();
      }
    }

    form.resetFields();
    formSearch.resetFields();
    handleRefresh();
    const dataRes = await getAllData();
    setListUser(dataRes);
    setListUserSearch(dataRes);
  };

  const handleRefresh = async () => {
    form.resetFields();
    setDescriptionData("");
    setContentData("");
    refDescription.current = "";
    refContent.current = "";
  };
  const handleRefreshSearch = async () => {
    formSearch.resetFields();
    const dataRes = await getAllData();
    setListUser(dataRes);
    setListUserSearch(dataRes);
  };

  const handleSearch = async () => {
    const dataForm = formSearch.getFieldsValue();
    if(!dataForm.userName) return;
    let dataRes = [...listUser]
    const data = dataRes.filter(item => {
      if(item.userName.toLocaleLowerCase().search(dataForm.userName.toLocaleLowerCase()) !== -1){
         return true;
      }
    })
    setListUserSearch(data);
  };
  const onClose = () => {
    setVisibleForm(false);
  };
  const showDrawer = () => {
    setVisibleForm(true);
  };
  const handleNewUser = () => {
    setDrawerTitle("Thêm người dùng");
    showDrawer();
    form.resetFields();
  };
  const onEdit = (key) => {
    const dataEdit = listUser.filter((item) => item.key === key);
    const dataRole = listRole.filter(
      (item) => item.roleName === dataEdit[0].roleName
    );
    const dataStatus = listStatus.filter(
      (item) => item.label === dataEdit[0].activeStatus
    );

    form.setFieldsValue({
      id: dataEdit[0].key,
      userName: dataEdit[0].userName,
      fullName: dataEdit[0].fullName,
      activeStatus: dataStatus[0].value,
      roleName: dataRole[0].roleName,
      role: dataRole[0]._id,
    });
    setFileList([
      {
        url: `${process.env.REACT_APP_IMAGE_URL}/${dataEdit[0]?.avatar}`,
        name: dataEdit[0]?.avatar,
      },
    ]);
    setPreviewImage(`${process.env.REACT_APP_IMAGE_URL}/${dataEdit[0]?.avatar}`);
    setPreviewTitle(dataEdit[0]?.avatar);
    setDrawerTitle("Sửa người dùng");
    showDrawer();
  };

  const onDelete = async (key) => {
    if (window.confirm("Bạn có chắc muốn xóa user này") == true) {
      const dataRes = await deleteUser(key);
      dataRes.status === 1
        ? message.success(`Xóa thành công! ${dataRes.message}`)
        : message.error(`Xóa thất bại! ${dataRes.message}`);

      handleRefreshSearch();
    }
    
  };

  const columns = [
    {
      title: "Tên đăng nhập",
      dataIndex: "userName",
    },
    {
      title: "Tên người dùng",
      dataIndex: "fullName",
    },
    {
      title: "Thời gian tạo",
      dataIndex: "createdAt",
    },
    {
      title: "Quyền",
      dataIndex: "role",
      render: (_, record) => {
        return <>{_.name}</>
      } 
      
    },
    {
      title: "Trạng thái",
      dataIndex: "activeStatus",
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) =>
        listUser.length >= 1 ? (
          <Space>
            <Tooltip title="Sửa">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => onEdit(record.key)}
              />
            </Tooltip>
            <Tooltip title="Xóa">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onDelete(record.key)}
              />
            </Tooltip>
          </Space>
        ) : null,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="User" pageTitle="Management Users" />
          <div>
            <Col>
              <Drawer
                title={drawerTitle}
                placement={"right"}
                width={"50%"}
                onClose={onClose}
                open={visibleForm}
                bodyStyle={{
                  paddingBottom: 80,
                }}
                style={{ marginTop: "70px" }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Row>
                    <Col hidden={true}>
                      <Form.Item name="id" label="Id">
                        <Input name="id" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="userName"
                        label="Tên đăng nhập"
                        rules={[
                          {
                            required: true,
                            message: "Please input user name!",
                          },
                          {
                            type: "userName",
                          },
                          {
                            type: "string",
                            min: 1,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter user name"
                          name="userName"
                          allowClear={true}
                        />
                      </Form.Item>

                      <Form.Item
                        name="role"
                        label="Quyền"
                        rules={[
                          {
                            required: true,
                            message: "Please select role!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select a role!"
                          allowClear
                          showSearch
                          name="roles"
                        >
                          {listRole.length > 0 &&
                            listRole.map((item) => {
                              return (
                                <Option key={item._id} value={item._id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="password"
                        label="Mật khẩu"
                        rules={[
                          {
                            required:drawerTitle == "Sửa người dùng"? false: true,
                            message: "Please input password!",
                          },
                          {
                            type: "password",
                          },
                          {
                            type: "string",
                            min: 1,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter post password!"
                          name="password"
                          allowClear={true}
                        />
                      </Form.Item>

                      <Form.Item
                        name="fullName"
                        label="Tên người dùng"
                        rules={[
                          {
                            type: "fullName",
                          },
                          {
                            type: "string",
                            min: 1,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter post full name!"
                          name="fullName"
                          allowClear={true}
                        />
                      </Form.Item>
                      <Form.Item
                        name="activeStatus"
                        label="Trạng thái"
                        rules={[
                          {
                            required: true,
                            message: "Please select active status!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select a active status!"
                          allowClear
                          showSearch
                          name="activeStatus"
                        >
                          {listStatus.length > 0 &&
                            listStatus.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      
                    </Col>
                   
                  </Row>
                  <Form.Item className="mt-3">
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={() => handleRefresh()}
                      >
                        Refresh
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Drawer>
            </Col>
          </div>
          <Row>
            <Col xs={12}>
              <Form
                form={formSearch}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row>
                  <Col hidden={true}>
                    <Form.Item name="id" label="Id">
                      <Input name="id" />
                    </Form.Item>
                  </Col>
                  <Col sm={3}>
                    <Form.Item
                      name="userName"
                      label="Tên đăng nhập"
                      rules={[
                        {
                          required: false,
                          message: "Please input user name!",
                        },
                        {
                          type: "userName",
                        },
                        {
                          type: "string",
                          min: 1,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter user name"
                        name="userName"
                        allowClear={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className="mt-3">
                  <Space>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleSearch()}
                    >
                      Tìm kiếm
                    </Button>
                    <Button type="primary" onClick={handleNewUser}>
                      Tạo mới
                    </Button>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRefreshSearch()}
                    >
                       Làm mới trang
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <div>
            <Table columns={columns} dataSource={listUserSearch} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;